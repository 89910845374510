export const settlement = {
    common: {
        floor: 'Floor',
    },
    realty_types: {
        undefined: "Indefinite",
        apartment: "Flat",
        parking_space: "Parking space",
        storage: "Storeroom",
        non_residential: "Non-residential", 
    },
    acceptance_status: {
        get_keys: 'Get the keys',
        information: 'Information',
    },
    acceptance_status_manager: {
        undefined: "Undefined",
        invited: "Invited",
        new: "New",
        appointmented: "Appointmented",
        prepared: "Prepared",
        successResult: "Completed without comments",
        resultWithRemarks: "Completed with comments",
        failResult: "Completed without an acceptance signature",
        cancelled: "Cancelled",
    },
    client: {
        list: {
            empty: 'The list of objects is currently empty',
            emptyDescription: 'After the purchase, all your objects will appear here',
            toRealtyCatalog: 'Go to the catalog'
        },
        appointment: {
            title: 'Making an appointment',
            text: 'Specify who will be present at the acceptance during the period',
            info: {
                title: 'Information for the owner',
                text: 'If a real estate object was acquired in shared ownership (several participants), then all participants in shared construction specified in the contract must be present at its acceptance.',
            },
            presence: {
                yourself: 'By yourself',
                representative: 'Representative by notarial power of attorney',
            },
            validation: {
                agreement: 'Familiarize yourself with the information'
            }
        },
        personal_data: {
            title: 'Check your details',
            text: 'If the data is incorrect, you can edit it, or mark it for the manager that the data is outdated.',
            form: {
                basic: 'Basic',
                passport: 'Passport',
                lastName: 'Last Name',
                firstName: 'First Name',
                patronymicName: 'Patronymic Name', 
                birthDate: 'Birth Date',
                placeOfBirth: 'Place of Birth',
                email: 'Email',
                passportSeries: 'Series and number',
                passportDate: 'Date of issue',
                passportCode: 'Department code',
                passportIssued: 'Issued by whom',
                registrationAddress: 'Address at the place of residence',
                residenceAddress: 'Address of the actual residence',
                isAddressMatch: 'The address of the actual residence coincides with the address of the residence permit',
            },
            placeholders: {
                lastName: 'Enter Last Name',
                firstName: 'Enter First Name',
                patronymicName: 'Enter Patronymic Name',
                birthDate: 'Enter Birth Date',
                placeOfBirth: 'Enter Place of Birth',
                email: 'Enter Email',
                passportSeries: 'Enter the passport series',
                passportNumber: 'Enter the passport number',
                passportDate: 'Enter the date of issue',
                passportCode: 'Enter the department code',
                passportIssued: 'Enter the name of the department',
                registrationAddress: 'Enter the address at the place of residence',
                residenceAddress: 'Enter the address of residence',
            }
        },
        date: {
            title: 'Select the date and time of acceptance',
            text1: 'Acceptance for your facility will take place',
            text2: 'Choose a convenient time for you in this period of dates.',
            dialog: {
                title: 'Acceptance is scheduled',
                text: 'The meeting will be held on',
            },
            dayValidation: 'The date must be no earlier than 2 days from the current date'
        },
        scheduled: {
            title: 'Acceptance is scheduled',
            date: 'Date of acceptance',
            cancel: 'Cancel acceptance',
            info: {
                title: 'Come 10 minutes before the meeting with your passport',
                text: 'All the parties to the agreement are present at the reception. The act is signed by the participant himself or his authorized representative under a notarized power of attorney.',
            },
            dialog_cancel: {
                title: 'Cancellation of acceptance',
                placeholder: 'Specify the reason for the cancellation of the acceptance',
            },
            dialog_success: {
                title: 'Acceptance has been cancelled',
                text: 'You can make an appointment for another available date and time.',
            },
        },
        completed: {
            remarks: 'Identified remarks',
            acceptance: {
                success: {
                    title: 'Acceptance completed',
                    alert: {
                        title: 'Congratulations',
                        text: 'The object has been successfully accepted.', 
                    }
                },
                remarks: {
                    title: 'Acceptance completed with remarks',
                    alert: {
                        title: 'Acceptance completed with remarks',
                        text: 'A technical request has been created based on the remarks and forwarded to the specialist. Once the work is completed, we will invite you again for an inspection.', 
                    }
                },
                withoutSign: {
                    title: 'Completed without the signature of the acceptance act',
                    alert: {
                        title: 'Completed without the signature of the acceptance act',
                        text: 'A technical request has been created based on the remarks and forwarded to the specialist. Once the work is completed, we will invite you again for an inspection.', 
                    }
                }
            }
        },
    },
    admin: {
        list: {
            title: "Real estate",
            tabs: {
                realty: "Realty",
                houses: "Houses"
            },
            house: {
                title: "House",
                acceptanceDays: "Acceptance days"
            },
            excel : {
                import: "Import",
                importStarted: "Import started"
            },
            acceptance: {
                sended: "Invitations for the acceptance have been sent out."
            },
            select: {
                sendMessage: 'Send message',
                objects: 'object/-s',
                resetSelect: 'Reset'
            },
            table: {
                filter: {
                    placholder: {
                        house: 'House',
                        status: 'Status',
                        realtyType: 'Real estate type',
                        realtyNumber: '№',
                        user: 'User'
                    },
                },
                acceptance: {
                    sended: "Invitations for the acceptance have been sent out."
                },
                columns: {
                    object: "Object",
                    status: "Status",
                    owner: "Owner",
                    phoneNumber: "Phone number"
                },
            },
        },
        monitoring: {
            setSchedule: 'Set a schedule',
            planningTime: 'Work time planning',
            workingDays: 'Working days',
            managers: 'Managers',
            timeSlotDescription: 'Сreate available time slots for managers on the specified dates and times so that clients can schedule appointments to receive keys.',
            scheduleSaved: 'The schedule is set'
        },
        appointments: {
            title: 'Acceptance appointments',
            placeholders: {
                house: 'All the house',
                status: 'Status',
                type: 'Type',
                object_number: 'Object number',
                client: 'Client',
                date: 'All dates',
                manager: 'Choose a manager',
                accepter: 'Enter the name of the receiver',
                comment: 'Enter a technical comment',
                remarks: 'Enter the comments',
            },
            table: {
                address: 'Address',
                section: 'Section',
                number: 'Number',
                date: 'Date and time',
                status: 'Status',
                fullNameManager: 'Manages',
            },
            realty_info: {
                realtyTypeName: "Type of rooms",
                square: "Total area",
                floorNumber: "Floor",
                finishing: "Finishing",
                projectName: "Project",
                sectionNumber: "Section",
            },
            dialog_titles: {
                deal: 'Deal',
                date: 'Date and time of the visit',
                manager: 'Manager',
                accepter: 'Accepter',
                owners: 'Owners',
                comment: 'Technical comment',
                remarks: 'Remarks',
            },
            dialog_owner: {
                passportSeries: 'Series and number',
                passportIssued: 'Issued by',
                passportDate: 'Date of issue',
                passportCode: 'Department code',
                registrationAddress: 'Address at the place of residence',
            },
            errors: {
                status: "The 'Invited' status cannot be selected",
            },
        },
    },
}