import { ru } from 'vuetify/locale';
import { settlement } from '~/plugins/i18n/ru/settlement.ru';
import { forms } from '~/plugins/i18n/ru/forms.ru';
import { coupon } from '~/plugins/i18n/ru/coupon.ru';
import { users } from '~/plugins/i18n/ru/users.ru';

export const russian =  {
    $vuetify: { ...ru },
    main: {
        hello: 'Добро пожаловать!!!',
        logout: 'Выход'
    },
    login: {
        mainTitle: 'Логин',
        phoneTitle: 'Номер телефона',
        codeTitle: 'Код',
        btnSendCode: 'Отправить код',
        btnAuth: 'Авторизоваться'
    },
    error: {
        title: 'Ошибка: ',
        mainPage: 'Вернуться на главную',
        pageNotFound: 'Страница не найдена',
        accessDenied: 'Доступ запрещен',
        data: 'Не удалось получить данные',
    },
    routes: {
        menu: 'Меню',
        main:'Главная страница',
        profile:'Профиль',
        partners: 'Клуб привилегий',
        favorites: 'Избранное',
        administration: 'Администрирование',
        users: 'Пользователи',
        emailVerified: 'Email подтвержден',
        categories: 'Категории партнеров',
        partner: 'Партнер',
        settlement: 'Объекты',
        settlementMonitoring: 'Мониторинг', 
        error: 'Ошибка',
        promocodes: 'Промокоды',
        certificates: 'Сертификаты',
        transactions: 'Транзакции',
        login: 'Авторизация',
        acceptances: "Записи на приемку",
    },
    email: {
        emailConfirmed: 'Ваш email подтвержден',
        goToCabinet: 'Перейти в кабинет',
        follow: 'Перейти'
    },
    coupon: coupon,
    users: users,
    forms: forms,
    settlement: settlement,
}
