import { en } from 'vuetify/locale';
import { settlement } from '~/plugins/i18n/en/settlement.en';
import { forms } from '~/plugins/i18n/en/forms.en';
import { coupon } from '~/plugins/i18n/en/coupon.en';
import { users } from '~/plugins/i18n/en/users.en';

export const english = {
    $vuetify: { ...en },
    main: {
        hello: 'Welcome!!!',
        logout: 'Sign out'
    },
    login: {
        mainTitle: 'Login',
        phoneTitle: 'Phone number',
        codeTitle: 'Code',
        btnSendCode: 'Send code',
        btnAuth: 'Authorize'
    },
    error: {
        title: 'Error: ',
        mainPage: 'Main page',
        pageNotFound: 'Page is not found',
        accessDenied: 'Access denied',
        data: 'Data could not be retrieved',
    },
    routes: {
        menu: 'Menu',
        main:'Home',
        profile:'Profiles',
        partners: 'Privilege club',
        favorites: 'Favorites',
        administration: 'Administration',
        users: 'Users',
        emailVerified: 'Email confirmed',
        categories: 'Categories of partners',
        partner: 'Partner',
        settlement: 'Objects', 
        settlementMonitoring: 'Monitoring', 
        error: 'Error',
        promocodes: 'Promocodes',
        certificates: 'Certificates',
        transactions: 'Transactions',
        login: 'Authorization',
        acceptances: "Acceptance appointments",
    },
    email: {
        emailConfirmed: 'Your email has been confirmed',
        goToCabinet: 'Go to the cabinet',
        follow: 'Follow'
    },
    coupon: coupon,
    users: users,
    forms: forms,
    settlement: settlement,  
}