export const users = {
    list: {
        title: 'Список пользователей',
        dialog: {
            coupons: {
                title: 'Купоны',
                close: 'Закрыть',
                table: {
                    headers:{
                        date: 'Дата',
                        partner: 'Партнер',
                        product: 'Продукт'
                    }
                }
            }
        },
        table: {
            name: 'ФИО',
            lastName:'Фамилия',
            firstName:'Имя',
            phoneNumber: 'Телефон',
            email: 'Email',
            role: 'Роль',
            coupons: 'Купонов',
            action: 'Действие',
            viewUser: 'Редактировать',
            blockUser: 'Заблокировать',
            unblockUser: 'Разблокировать',
            notAllowedBlockYourself: 'Нельзя заблокировать себя',
            notAllowedUnBlockYourself: 'Нельзя разблокировать себя',
        },
        filter: {
            search: 'Поиск...',
            isEnabled: 'Активен',
            find: 'Найти',
            clear: 'Очистить'
        }
    },
    card: {
        title: 'Личная информация',
        lastName: 'Фамилия',
        firstName: 'Имя',
        birthDate:'Дата рождения',
        email: 'Email',
        emailVerify: 'Подтвердить',
        phoneNumber: 'Телефон',
        role: 'Роль',
        createDate: 'Дата регистрации',
        changeRole: 'Изменение роли',
        block: 'Заблокировать',
        unblock: 'Разблокировать',
        save: 'Сохранить',
        forSendingIntrestingNews: 'Самые интересные предложения будем слать туда',
        uniqueIdentifier: "Уникальный идентификатор",
        uniqueIdentifierNotChange: "Уникальный идентификатор, нельзя изменить",
        selfRoleChangingError: "Нельзя менять роль самому себе.",
        allowChangeEmailOnceIn3Minutes: 'Изменять email разрешено только раз в 3 минуты.',
        emailVerifyMessageSent: 'Письмо для подтверждения Email отправлено'
    }
};